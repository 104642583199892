/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import MidContainer from '@sw/components/Layout/MidContainer';

/**
 * Renders the SingleImage Card component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @param my
 * @param px
 * @param w
 * @param h
 * @param maxWidth
 * @param mx
 * @returns {ReactElement} SingleImage Card component
 */

const SingleImage = ({
  sectionContent,
  region,
  lang,
  my,
  px,
  w,
  h,
  maxWidth,
  mx,
  schemaData,
}) => {
  const imgSchemaData = schemaData
    ? schemaData
    : {
      '@context': 'https://schema.org',
      '@type': 'ImageObject',
      '@id': nanoid(4),
      name: sectionContent?.image?.alt,
      url: sectionContent?.image?.url,
      contentSize: sectionContent?.image?.size,
      datePublished: sectionContent?.image?._createdAt,
    };

  return sectionContent.image?.url ? (
    <MidContainer
      // w={sectionContent.fieldId === 'content-width' ? '1170px' : '1800px'}
      w={sectionContent.fieldId === 'content-width' ? '1170px' : '100%'}>
      <Box
        textAlign="center"
        position="relative"
        mx={'auto'}
        my={my || '20px'}
        px={{ base: px || '0px', md: '0' }}
        className="image-container"
      // maxW={{ base: 302, sm: 330, lg: 470 }}
      >
        <LazyLoadImageComponent
          h={h || '100%'}
          alt={sectionContent.image?.alt}
          title={sectionContent.image?.title}
          textAlign="center"
          src={sectionContent?.image?.url}
          w={w || '100%'}
          maxWidth={maxWidth || '100%'}
          mx={{ sm: mx || '0', lg: mx || '0' }}
          borderRadius="10px"
        />
      </Box>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(imgSchemaData) }}
      />
    </MidContainer>
  ) : null;
};

export default SingleImage;
