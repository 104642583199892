/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import qs from 'qs';

/* Internal Imports */
/* Components */
import MidContainer from '@ihs/components/Layout/MidContainer';

/* Services */

/**
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} GoogleMap component
 */
const GoogleMap = ({ sectionContent }) => {
  const updateLink = url => {
    let finalUrl = url;
    if (url?.indexOf('/embed?') < 0) {
      if (url.indexOf('https://www.google.com/maps?') > -1) {
        const parsedUrl = qs
          .parse(url, {
            charset: 'utf-8',
            charsetSentinel: true,
          })
          ?.['https://www.google.com/maps?ll']?.split(',');
        // consoleLog('coords', parsedUrl, parsedUrl['https://www.google.com/maps?ll']);
        if (parsedUrl?.length) {
          finalUrl = `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d${parsedUrl[1]}!3d${parsedUrl[0]}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098`;
        }
      } else if (url.indexOf('https://www.google.com/maps/') > -1) {
        const cordinates = url
          ?.split('/')
        [url.split('/').length - 1]?.replace('@', '')
          ?.split(',');
        if (cordinates) {
          finalUrl = `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d${cordinates[1]}!3d${cordinates[0]}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098`;
        }
      }
    }
    return finalUrl;
  };

  return sectionContent?.url && (
    <MidContainer w={'1330'}>
      <Box
        as="iframe"
        width="100%"
        maxW={'1330px'}
        height="480px"
        allowFullScreen
        frameBorder="0"
        loading="lazy"
        src={updateLink(sectionContent?.url)}
        m="0 auto"
      />
    </MidContainer>
  );
};

export default GoogleMap;
