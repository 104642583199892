/* Built In Imports */
/* External Imports */
import { Box, Center } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Services */

/**
 * Renders the TopKolam component.
 *
 * @param {object} sectionContent - Content from api.
 * @returns {ReactElement} TopKolam component.
 */

const TopKolam = ({ sectionContent, margin }) => {
    const kolamTypes = {
        'health-red-flower': <RedFlower />,
        "ihs-spiral": <Spiral />,
        'ihs-waves': <Waves />,
        centers: <BlueFlower />,
        'Y&M': <BlueFlower />,
        events: <BlueFlower />,
        'yoga-sandhya-infinity-yellow': <YogaSandhyaInfinityYellow />,
        'ys-benefits-kolam': <YsBenefitsKolam />,
        'no-decoration': <></>
    };

    return (
        <Box textAlign="center" margin={margin || "15px 0"}>
            {kolamTypes[sectionContent.style]}
        </Box>
    );
};
const Waves = ({ ...props }) => {
    return (
        <Center>
            <LazyLoadImageComponent
                loading="lazy"
                //  minHeight="55px"
                alt="Waves"
                // title="kolam-blue"
                w={{ base: '183px', md: '209px' }}
                height={{ base: '21px', md: '24px' }}
                src={`${config.imgPath}/d/46272/1700033980-a-1.png`}
                {...props}
                opacity="0.6"
            />
        </Center>
    )
}
const Spiral = ({ ...props }) => {
    return (
        <Center>
            <LazyLoadImageComponent alt="spiral" {...props} w="79.394px" height="auto" loading="lazy"
                src={`${config.imgPath}/d/46272/1700033975-a.png`}
            />
        </Center>
    )
}
const RedFlower = ({ ...props }) => {
    return (
        <LazyLoadImageComponent
            loading="lazy"
            height="58px"
            alt="kolam-red"
            title="kolam-red"
            src={`${config.imgPath}/d/46272/1630931831-wheat-mountain.svg`}
            // src="https://isha.sadhguru.org/profiles/isha/themes/ishatheme/assets/wheat-mountain.svg"
            // mb={'2vw'}
            width="108px"
            {...props}
        />
    );
};

const BlueFlower = ({ ...props }) => {
    return (
        <LazyLoadImageComponent
            loading="lazy"
            // minHeight="55px"
            alt="kolam-blue"
            title="kolam-blue"
            w="155px"
            src={`${config.imgPath}/d/46272/1663645235-spandahall-banner.svg`}
            // src="https://isha.sadhguru.org/profiles/isha/themes/ishatheme/assets/SpandaHall-banner.svg"
            // mb={'2vw'}
            {...props}
        />
    );
};
const YogaSandhyaInfinityYellow = ({ ...props }) => {
    return (
        <LazyLoadImageComponent
            loading="lazy"
            //  minHeight="55px"
            alt="yoga-sandhya-infinity-yellow"
            // title="kolam-blue"
            w={{ base: '139px', md: '254px' }}
            height={{ base: '23px', md: '42px' }}
            src={`${config.imgPath}/d/46272/1689149109-group-3573.png`}
            // src="https://isha.sadhguru.org/profiles/isha/themes/ishatheme/assets/SpandaHall-banner.svg"
            // mb={'2vw'}
            {...props}
        />
    );
};
const YsBenefitsKolam = ({ ...props }) => {
    return (
        <LazyLoadImageComponent
            loading="lazy"
            // minHeight="55px"
            alt="yoga-sandhya-infinity-yellow"
            // title="kolam-blue"
            mb="15px"
            w={{ base: '139px', md: '254px' }}
            height={{ base: '23px', md: '42px' }}
            src={`${config.imgPath}/d/46272/1689149130-group-3740.png`}
            // src="https://isha.sadhguru.org/profiles/isha/themes/ishatheme/assets/SpandaHall-banner.svg"
            // mb={'2vw'}
            {...props}
        />
    );
};

export default TopKolam;
