/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/**
 * Renders the HillImageImage Card component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @param my
 * @param px
 * @param w
 * @param h
 * @param maxWidth
 * @param mx
 * @returns {ReactElement} HillImageImage Card component
 */

const HillImageImage = ({ sectionContent }) => {
  return sectionContent.image?.url ? (
    // <MidContainer background="red"
    //    w={sectionContent.fieldId === 'content-width' ? '1170px' : '100%'}>
    <Box
      textAlign="right"
      position="relative"
      mx={'auto'}
      mb={'30px'}
      px={{ base: '15px', md: '30px' }}
      className="image-container"
      w="100%"
    >
      <LazyLoadImageComponent
        h={'100%'}
        alt={sectionContent.image?.alt}
        title={sectionContent.image?.title}
        float="right"
        textAlign="center"
        src={sectionContent?.image?.url}
        ml="auto"
        w={'100%'}
        maxWidth={{ base: '100%', md: '536px' }}
        //  m={{ sm: '0', md: '0 auto' }}
        borderRadius="10px"
      />
    </Box>
  ) : // </MidContainer>
  null;
};

export default HillImageImage;
