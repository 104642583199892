/* Built In Imports */

/* External Imports */
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, List, Show } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Services */

/**
 * Renders the Card Info 2 Card component
 *
 * @param {string} region - region for section
 * @param {string} lang - lang for section
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the page
 * @param {string} lang - Language of the page
 * @returns {ReactElement} Card Info2 Card component
 */

const CardInfo2 = ({ sectionContent }) => {
  // console.log("cards", sectionContent)
  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        textAlign={'center'}
        display="block"
        top={{ base: '33%', md: '45%' }}
        zIndex="10"
        cursor="pointer"
        mr={{ base: '6px', md: '48px', lg: '48px', xl: '48px' }}
        border="0"
        w={{ base: '38px', md: '48px', lg: '48px', xl: '48px' }}
        h={{ base: '38px', md: '48px', lg: '48px', xl: '48px' }}
        bgPos="center"
        bgRepeat="no-repeat"
        right={{ xl: '0px', base: '3px' }}
        onClick={onClick}
        placeItems="center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={{ base: '38', xl: '63' }}
          height={{ base: '38', xl: '61' }}
          viewBox="0 0 63 61"
          fill="none"
        >
          <path
            d="M17.6482 57.861C33.8333 62.5435 53.4041 61.9291 60.4919 43.527C64.5163 33.0672 61.6905 20.527 54.1795 12.2078C47.422 4.72134 31.6955 -3.15009 21.446 1.28664C17.9048 2.82652 13.3343 4.31453 10.9207 7.39703C8.04572 10.9901 4.65742 14.635 2.91277 18.9489C0.0377693 25.9821 -1.45024 35.8385 1.98993 42.8198C3.63357 46.1562 5.12157 50.4701 8.3051 52.6243C9.64021 53.4461 17.5444 58.2187 17.6482 57.861C17.6482 57.9129 18.4181 58.0658 17.6482 57.861Z"
            fill="#FBAD18"
          />
          <ChevronRightIcon
            width={{ base: '26', md: '43' }}
            height={{ base: '26', md: '43' }}
            color="#353535"
            fontWeight="100"
          />
        </svg>
      </Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        display="block"
        top={{ base: '33%', md: '45%' }}
        //top={{xl:"261px"}}
        zIndex="10"
        cursor="pointer"
        //  bgSize="13px"
        ml={{ base: '6px', md: '48px', lg: '48px', xl: '48px' }}
        placeItems="center"
        // boxShadow="0px 28.8px 53.6px rgba(0, 0, 0, 0.2), 0px 18.6667px 31.3907px rgba(0, 0, 0, 0.151852), 0px 11.0933px 17.0726px rgba(0, 0, 0, 0.121481), 0px 5.76px 8.71px rgba(0, 0, 0, 0.1), 0px 2.34667px 4.36741px rgba(0, 0, 0, 0.0785185), 0px 0.533333px 2.10926px rgba(0, 0, 0, 0.0481481)"
        border="0"
        w={{ base: '38px', md: '48px', lg: '48px', xl: '48px' }}
        h={{ base: '38px', md: '48px', lg: '48px', xl: '48px' }}
        textAlign="center"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        left={{ xl: '0px', base: '3px' }}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={{ base: '38', xl: '63' }}
          height={{ base: '38', xl: '61' }}
          viewBox="0 0 63 61"
          fill="none"
        >
          <path
            d="M45.1604 57.861C28.9753 62.5435 9.40454 61.9291 2.3167 43.527C-1.70775 33.0672 1.1181 20.527 8.62913 12.2078C15.3866 4.72134 31.1131 -3.15009 41.3626 1.28664C44.9038 2.82652 49.4743 4.31453 51.8879 7.39703C54.7629 10.9901 58.1512 14.635 59.8958 18.9489C62.7708 25.9821 64.2588 35.8385 60.8187 42.8198C59.175 46.1562 57.687 50.4701 54.5035 52.6243C53.1684 53.4461 45.2642 58.2187 45.1604 57.861C45.1604 57.9129 44.3905 58.0658 45.1604 57.861Z"
            fill="#FBAD18"
          />
          <ChevronLeftIcon
            width={{ base: '26', md: '43' }}
            h={{ base: '26', md: '43' }}
            color="#353535"
          />
        </svg>
      </Box>
    );
  };

  const settingsSmall = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    //  centerPadding: '25px',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: dots => (
      <Box
        display={{ base: 'block', md: 'none', xl: 'block' }}
        position="absolute"
        bottom={{ base: '103px', xl: '69px' }}
      >
        <List margin="0px"> {dots} </List>
      </Box>
    ),
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box
      display="flex"
      w="95%"
      flexWrap="wrap"
      gridGap="18px"
      //  gridRowGap="60px"
      justifyContent="center"
      mx="auto"
      mb="50px"
    >
      {sectionContent?.cards?.map((secCon, index) => {
        return (
          <>
            <Show above="md">
              <Box
                key={nanoid()}
                maxW={{ base: '330px', lg: '326px' }}
                justifyContent="center"
                mx="auto"
              >
                <LazyLoadImageComponent
                  // onClick={() =>
                  //   redirect(refineUrlDomain(secCon.buttonLink, region, lang))
                  // }
                  src={secCon.thumbnail?.url}
                  alt={secCon.thumbnail?.alt}
                  title={secCon.thumbnail?.title}
                  h={{ base: '200px', md: '230px', xl: '309px' }}
                  objectFit="cover"
                  w="full"
                  borderRadius="8px"
                  maxW="330px"
                  mx="auto"
                />
              </Box>
            </Show>
            <Show below="md">
              <Box
                py="40px"
                maxW="1330px"
                w="100%"
                mx="auto"
                position="relative"
                objectFit="cover"
              >
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  display={{
                    base: 'block',
                    md: 'block',
                    lg: 'block',
                    xl: 'block',
                  }}
                >
                  <LazyLoadImageComponent
                    h={{
                      base: '714px',
                      md: '629px',
                      xl: '593px',
                      '2xl': '710px',
                    }}
                    src={secCon.thumbnail?.url}
                    alt={secCon.thumbnail?.alt}
                    title={secCon.thumbnail?.title}
                  />
                </Box>

                <Box
                  w={{ base: '100%', md: '100%', lg: '100%', xl: '100%' }}
                  height="auto"
                  maxW="1330px"
                  // position="relative"
                  zIndex="1"
                >
                  <Slider {...settingsSmall}>
                    {sectionContent?.testimonials?.map(testimonial => {
                      return (
                        <SliderCard key={nanoid()} testimonial={testimonial} />
                      );
                    })}
                  </Slider>
                </Box>
                <style global jsx>
                  {`
             .slick-dots li.slick-active button:before {
               color: #FBAD18;
               .slick-dots li button:before {
                 font-size: 8px;
                 color: grey;
                }
              }
             
             @media (max-width: 992px) {
               ul.slick-dots {
                 display: block !important;
                 bottom: 85px !important;
               }
                @media (max-width: 991px) {
               ul.slick-dots {
                 display: none !important;
                
               }
               @media (max-width: 767px) {
                 ul.slick-dots {
                   display: block !important;
                   bottom: 85px !important;
                 }
                 ul.slick-dots {
                   bottom: 85px !important;
                 }
     
               
                   @media (max-width:1330px){
                     .TestimonialGroupV2Home .slick-dots {
                     padding-bottom: 89px !important;
                   }
                   
                 }
              `}
                </style>
              </Box>
            </Show>
          </>
        );
      })}
    </Box>
  );
};

export default CardInfo2;
