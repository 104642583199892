/* Built In Imports */
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import { Box, Center, Flex, Image, Show, Text, chakra } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { AiFillCaretDown, AiOutlineArrowsAlt } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */
/* Styles */
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';
// import 'react-image-lightbox/style.css';
// import "react-lightbox-pack/dist/index.css";
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */

const Slider = ({ sectionContent }) => {
  const [my_swiper, set_my_swiper] = useState({});
  const SwiperSl = chakra(SwiperSlide);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [sIndex, setSIndex] = useState(0);
  const pagination = {
    clickable: true,
    spaceBetween: 40,
    el: '.custom-pagination',
    height: '40px',
    // bulletClass: "bullet not-active", //tailwind styles don't work here?
    bulletActiveClass: 'active-bullet bullet',
    // renderBullet: (index, className) => {
    //   return <div> Hello world </div>;
    // },
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  if (sectionContent?.images.length === 2 && !isMobile) {
    return (
      <Flex
        width="100%"
        maxW="670px"
        mx="auto"
        gridGap="15px"
        mt="20px"
        mb="30px"
      >
        {sectionContent.images.map(image => {
          return (
            <Box flex={1} borderRadius="8px" h="310px">
              <Image
                borderRadius="8px"
                objectFit="cover"
                h="100%"
                width="100%"
                src={image?.url}
                alt={image?.alt || image?.title || ''}
              />
            </Box>
          );
        })}
      </Flex>
    );
  }
  if (sectionContent?.images?.length > 1) {
    return (
      <Box
        maxW="720px"
        overflow="visible"
        w="100%"
        mx="auto"
        minH={{ base: 'auto', sm: '250px', md: '450px' }}
        px={{ base: '0px', md: '0' }}
        my="20px"
      >
        <Swiper
          onSwiper={swiper => {
            // Delay execution for the refs to be defined
            setTimeout(() => {
              // Override prevEl & nextEl now that refs are defined
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;

              // Re-init navigation
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
          modules={[Navigation, Pagination, EffectFade]}
          loop={true}
          slidesPerView={1}
          onInit={ev => {
            set_my_swiper(ev);
          }}
          // navigation={true}
          navigation={{
            // Both prevEl & nextEl are null at render so this does not work
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          pagination={pagination}
          spaceBetween={50}
          // onSwiper={(swiper) => console.log(swiper)}
          initialSlide={sIndex}
          // slidesPerView={1}
          // onSlideChange={(e) => { setSIndex(e.activeIndex); }}
          style={{ padding: isMobile ? '0 15px' : '0 23px' }}
        >
          {sectionContent?.images?.map(image => {
            return (
              <SwiperSl
                key={nanoid()}
                onTouchStart={() => my_swiper.slideNext()}
                onClick={() => my_swiper.slideNext()}
                maxWidth="670px"
                width="100%"
                ml={{ base: '0px', md: '4px' }}
              >
                <Box w="100%" pos="relative" borderRadius="12px">
                  <Box
                    pos="absolute"
                    top="0"
                    left="0"
                    height="100%"
                    width="20%"
                    borderRadius="12px 0 0 12px"
                    bg="linear-gradient(90deg, rgba(37, 22, 22, 0.47) 0%, rgba(43, 25, 25, 0.00) 100%)"
                    opacity="0.4"
                  ></Box>
                  <Box
                    pos="absolute"
                    top="0"
                    right="0"
                    height="100%"
                    width="20%"
                    borderRadius="0 12px 12px 0"
                    bg="linear-gradient(270deg, rgba(37, 22, 22, 0.47) 0%, rgba(43, 25, 25, 0.00) 100%)"
                    opacity="0.4"
                  ></Box>

                  <Image
                    src={image.url}
                    alt={image?.alt || image?.title || ''}
                    // mt="42%"
                    w="100%"
                    rounded="md"
                    height="100%"
                    bgSize="cover"
                    bgPos="center"
                    borderRadius="12px"
                  />
                </Box>
              </SwiperSl>
            );
          })}
          <Box
            className="custom-pagination"
            w="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            h={{ base: '25px', md: '50px' }}
          ></Box>
          <Center
            filter="drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.10)) drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.09)) drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.05)) drop-shadow(0px 13px 5px rgba(0, 0, 0, 0.01)) drop-shadow(0px 20px 6px rgba(0, 0, 0, 0.00))"
            fontSize={{ base: '22px', md: '30px' }}
            top={{ base: 'calc(50% - 25.5px)', md: 'calc(50% - 36.5px)' }}
            ref={navigationPrevRef}
            borderRadius="50%"
            background={`#FBAD18`}
            _hover={{
              bg: '#DB9000',
              color: '#fff',
            }}
            color="#622210"
            pos="absolute"
            bgSize="cover"
            bgRepeat="no-repeat"
            w={{ base: 31, md: 53 }}
            h={{ base: 31, md: 53 }}
            zIndex={1000}
            left={{ base: '0', md: '0' }}
            cursor="pointer"
          >
            <ChevronLeftIcon />
          </Center>
          <Center
            filter="drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.10)) drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.09)) drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.05)) drop-shadow(0px 13px 5px rgba(0, 0, 0, 0.01)) drop-shadow(0px 20px 6px rgba(0, 0, 0, 0.00))"
            color="#622210"
            fontSize={{ base: '22px', md: '30px' }}
            top={{ base: 'calc(50% - 25.5px)', md: 'calc(50% - 36.5px)' }}
            borderRadius={'50%'}
            right={{ base: '0', md: '0' }}
            background={`#FBAD18`}
            ref={navigationNextRef}
            cursor="pointer"
            pos="absolute"
            bgSize="cover"
            bgRepeat="no-repeat"
            w={{ base: 31, md: 53 }}
            h={{ base: 31, md: 53 }}
            zIndex={1000}
            _hover={{
              bg: '#DB9000',
              color: '#fff',
            }}
          >
            <ChevronRightIcon />
          </Center>
        </Swiper>
        <style global jsx>
          {`
            .swiper-slide {
              -webkit-backface-visibility: hidden;
              -webkit-transform: translate3d (0, 0, 0);
            }

            .custom-pagination span {
              width: 8px !important;
              display: inline-block;
              height: 8px !important;
              border-radius: 50%;
              background: #fbad18;
              opacity: 0.3;
              margin: 0 5px;
              cursor: pointer;
            }
            .custom-pagination .active-bullet {
              opacity: 1;
              width: 8px !important;
              display: inline-block;
              height: 8px !important;
              border-radius: 50%;
              background: #fbad18;
              cursor: pointer;
            }
          `}
        </style>
      </Box>
    );
  }
};

const IhTiles = ({ sectionContent }) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [toggle, setToggle] = useState(false);
  const [sIndex, setSIndex] = useState(0);
  const [images, setImages] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  sectionContent?.images.forEach(element => {
    element.image = element.url;
  });

  // Handler
  // const lightBoxHandler = (state, sIndex) => {
  //   setToggle(state);
  //   setSIndex(sIndex);
  // };

  const addImages = () => {
    setImages([
      ...images,
      ...sectionContent.images.slice(images?.length, images?.length + 8),
    ]);
  };

  const openImage = index => {
    if (!isMobile) {
      setSIndex(index);
      setToggle(true);
    } else if (isMobile) {
      setToggle(false);
    }
  };

  useEffect(() => {
    setImages([...sectionContent.images.slice(0, 9)]);
  }, []);

  return (
    <Box
      bgImage={{
        base: `${config.imgPath}/d/46272/1700033948-rectangle-42442.png`,
        md: `${config.imgPath}/d/46272/1700033943-vector-24.png`,
      }}
      w="100%"
      maxW="1330px"
      mx="auto"
      bgPos="top"
      bgRepeat="no-repeat"
      bgSize="cover"
      px="16px"
    >
      <Flex
        width="100%"
        mx="auto"
        maxW={{ md: 690, lg: '1170px' }}
        mt={{ base: '20px', md: '30px' }}
        gridGap={{ base: '0', md: '8px', xl: '16px' }}
        height={{ md: 'auto', lg: 'auto' }}
        flexWrap={{ base: 'wrap', lg: 'no-wrap' }}
        alignItems="flex-start"
        className="gallery"
        py={{ base: '60px', md: '80px' }}
      >
        {images?.map((el, index) => {
          return (
            <Box
              display="flex"
              order={{
                base: index !== images.length - 1 ? index + 2 : 2,
                md: 'initial',
              }}
              key={nanoid(4)}
              width={{
                base: '100%',
                md: index === 0 || index === images.length - 1 ? '49%' : '24%',
                lg: index === 0 || index === images.length - 1 ? '49%' : '24%',
              }}
              maxW={{
                base: '360px',
                md:
                  index === 0 || index === images.length - 1
                    ? '573px'
                    : '280px',
              }}
              height={{
                base: '217px',
                md:
                  index === 0 || index === images.length - 1 ? '227px' : '94px',
                lg:
                  index === 0 || index === images.length - 1
                    ? '381px'
                    : '157px',
              }}
              mx="auto"
              mb={{ base: '20px', md: '0' }}
              marginTop={{
                md: index === images.length - 1 ? '-137px' : 0,
                lg: index === images.length - 1 ? '-220px' : '0px',
              }}
              pos="relative"
            >
              <Center
                rounded="full"
                pos="absolute"
                width="51px"
                height="51px"
                display={{ base: 'none', md: 'flex' }}
                bg="rgba(0, 0, 0, 0.66)"
                bottom={17}
                right={17}
                color="#FFFFFF"
                fontSize="32px"
                onClick={() => openImage(index)}
                cursor={{ base: 'auto', md: 'pointer' }}
              >
                <AiOutlineArrowsAlt />
              </Center>
              <Image
                alt={el?.title || el.alt || ''}
                height={{
                  md:
                    index === 0 || index === images.length - 1
                      ? '227px'
                      : '94px',
                  lg:
                    index === 0 || index === images.length - 1
                      ? '381px'
                      : '157px',
                }}
                borderRadius="1rem"
                src={el?.url}
                w="100%"
                onClick={() => openImage(index)}
                cursor={{ base: 'auto', md: 'pointer' }}
              />
            </Box>
          );
        })}
        {toggle && (
          <>
            <Show above="md">
              <Box
                bottom="10px"
                right="15px"
                zIndex="1001"
                color="#ababab"
                pos="fixed"
                fontFamily="FedraSansStd-book"
                w="100%"
                h="100%"
                top="0"
                left="0"
                bgColor="rgba(0,0,0,.9)"
                onClick={() => setToggle(false)}
              ></Box>
              <Box
                maxW="964px"
                zIndex="1002"
                pos="fixed"
                w={{ base: '100dvw', md: '70%' }}
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                px={{ base: '20px', md: '0' }}
              >
                <Center
                  onClick={() => setToggle(false)}
                  width={{ base: 38, md: 76 }}
                  bg="#fff"
                  rounded="full"
                  zIndex="1002"
                  pos="fixed"
                  right={{ base: '19px', md: '-16px' }}
                  top={{ base: '-19px', md: '-38px' }}
                  height={{ base: 38, md: 76 }}
                  cursor="pointer"
                  fontFamily="FedraSansStd-book"
                >
                  <CloseIcon />
                </Center>
                <Swiper
                  modules={[Navigation]}
                  //spaceBetween={50}
                  navigation={{
                    // Both prevEl & nextEl are null at render so this does not work
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  // centeredSlides
                  initialSlide={sIndex}
                  slidesPerView={1}
                  onSlideChange={e => {
                    setSIndex(e.activeIndex);
                  }}
                  onSwiper={swiper => {
                    // Delay execution for the refs to be defined
                    setTimeout(() => {
                      // Override prevEl & nextEl now that refs are defined
                      swiper.params.navigation.prevEl =
                        navigationPrevRef.current;
                      swiper.params.navigation.nextEl =
                        navigationNextRef.current;

                      // Re-init navigation
                      swiper.navigation.destroy();
                      swiper.navigation.init();
                      swiper.navigation.update();
                    });
                  }}
                  // style={{ padding: '0 23px' }}
                  // onSwiper={(swiper) => console.log(swiper)}
                >
                  {sectionContent?.images?.map((el, index) => {
                    return (
                      <SwiperSlide
                        key={el}
                        virtualIndex={sIndex}
                        style={{ padding: '0 26px' }}
                      >
                        <Box pos="relative">
                          {el?.title && (
                            <Box
                              w="100%"
                              pos="absolute"
                              bottom={0}
                              height="auto"
                              py="19px"
                              bg="rgba(0, 0, 0, 0.63)"
                            >
                              <Text
                                fontFamily="Zilla Slab"
                                fontSize="16px"
                                lineHeight="26px"
                                fontWeight="400"
                                color="#fff"
                                textAlign="center"
                              >
                                {el?.title}
                              </Text>
                            </Box>
                          )}
                          <Image
                            alt={el?.alt || el?.title || ''}
                            // mt="42%"
                            height="auto"
                            bgSize="cover"
                            bgPos="center"
                            borderRadius="0"
                            src={el?.url}
                          />
                        </Box>
                      </SwiperSlide>
                    );
                  })}
                  <Center
                    filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.10)) drop-shadow(0px 7px 7px rgba(0, 0, 0, 0.09)) drop-shadow(0px 15px 9px rgba(0, 0, 0, 0.05)) drop-shadow(0px 27px 11px rgba(0, 0, 0, 0.01)) drop-shadow(0px 41px 12px rgba(0, 0, 0, 0.00))"
                    fontSize="43px"
                    fontWeight="light"
                    borderRadius="50%"
                    ref={navigationPrevRef}
                    background={`url(${config.imgPath}/d/46272/1701245566-arrow-left.svg)`}
                    pos="absolute"
                    top="calc(50% - 26.5px)"
                    bgSize="cover"
                    bgRepeat="no-repeat"
                    w={53}
                    h={53}
                    zIndex={1000}
                    left="0"
                    _hover={{
                      color: '#fff',
                      bgImage: `url(${config.imgPath}/d/46272/1701325617-left-hover-bg.svg)`,
                    }}
                    cursor="pointer"
                  >
                    <ChevronLeftIcon />
                  </Center>
                  <Center
                    filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.10)) drop-shadow(0px 7px 7px rgba(0, 0, 0, 0.09)) drop-shadow(0px 15px 9px rgba(0, 0, 0, 0.05)) drop-shadow(0px 27px 11px rgba(0, 0, 0, 0.01)) drop-shadow(0px 41px 12px rgba(0, 0, 0, 0.00))"
                    fontWeight="light"
                    fontSize="43px"
                    borderRadius="50%"
                    right="0"
                    bg={`url(${config.imgPath}/d/46272/1701245566-arrow-left.svg)`}
                    ref={navigationNextRef}
                    cursor="pointer"
                    pos="absolute"
                    top="calc(50% - 26.5px)"
                    bgSize="cover"
                    bgRepeat="no-repeat"
                    w={53}
                    h={53}
                    zIndex={1000}
                    _hover={{
                      color: '#fff',
                      bgImage: `url(${config.imgPath}/d/46272/1701325617-left-hover-bg.svg)`,
                    }}
                  >
                    <ChevronRightIcon />
                  </Center>
                </Swiper>
                <style global jsx>
                  {`
                    .swiper-button-disabled {
                      visibility: hidden;
                    }
                  `}
                </style>
              </Box>
            </Show>
          </>
        )}
      </Flex>
      {images.length !== sectionContent?.images.length && (
        <Box
          width="50px"
          title="Load more"
          cursor="pointer"
          mb="60px"
          mx="auto"
          onClick={addImages}
        >
          <AiFillCaretDown color=" #3c6891" size="55px" />
        </Box>
      )}
    </Box>
  );
};

export const Gallery = ({ sectionContent }) => {
  const galleryType = {
    'ihs-tiles': <IhTiles sectionContent={sectionContent} />,
    slider_single_image: <Slider sectionContent={sectionContent} />,
  };
  return (
    <Box>
      {galleryType[sectionContent?.galleryType]}
      <style jsx global>
        {`
          ._3bkri {
            background-color: #49423b;
          }
          ._3bkri:hover {
            background-color: #49423b;
          }
          ._20cg_:focus,
          ._3bkri:focus {
            box-shadow: 0px 0px 0px 3px #49423b;
          }
          @media (max-width: 767px) {
            .gallery button {
              // display: block;
            }
            ._3ZUEV {
              top: 1rem;
              right: 1rem;
            }
            ._2pWTS {
              left: 1%;
            }
            ._18xMp {
              right: 1%;
            }
            ._3bkri {
              background-color: transparent;
              width: 2rem;
            }
            ._3bkri:hover {
              background-color: transparent;
            }
            ._20cg_:focus,
            ._3bkri:focus {
              box-shadow: none;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default Gallery;
