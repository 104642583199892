/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports*/
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@ihs/components/Utility/StructuredTextParser';

/**
 *
 * @param {Object} card
 * @returns
 */
const AdmissionCircularImage = ({ card, index }) => {
  const isEvenIndex = index % 2 == 0;
  const flexDirection = !isEvenIndex ? 'row-reverse' : 'row';

  return (
    <Box
      display="flex"
      maxW="670px"
      key={nanoid()}
      w="100%"
      // gridGap={{ base: "18px", md: "30px" }}
      gridColumnGap={{ base: '18px', md: '40px' }}
      mb={{ base: '10px', md: '30px' }}
      flexDirection={{
        md: flexDirection,
        base: 'column-reverse',
      }}
      mx={{ base: '16px', md: '0' }}
    >
      {card?.thumbnails?.map(thumbnail => {
        return (
          <Box key={nanoid()}>
            <LazyLoadImageComponent
              src={thumbnail.url}
              maxW={{
                base: isEvenIndex ? '322px' : '331.7px',
                xl: isEvenIndex ? '257' : '331.7px',
              }}
              maxH={{
                base: isEvenIndex ? '249px' : '261px',
                xl: isEvenIndex ? '249px' : '261px',
              }}
              mx={{ base: 'auto' }}
              textAlign="center"
              mt="0px"
              w="100%"
              h="100%"
              objectFit="cover"
            />
          </Box>
        );
      })}

      <Box
        color="#3B444B"
        fontFamily="Zilla Slab"
        fontSize="18px"
        fontStyle="normal"
        fontWeight="400"
        lineHeight="30px"
      >
        <Box color="#DC6704" fontWeight="600">
          {card.title}
          <StructuredTextParser
            str={render(card.description)}
            mt="0"
            p="0"
            maxW={{ xl: isEvenIndex ? '354px' : '349px' }}
            textAlign="left"
            fontWeight="normal"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdmissionCircularImage;
