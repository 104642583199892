/* Built In Imports */
/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import config from '@config';
import StructuredTextParser from '@ihs/components/Utility/StructuredTextParser';

/* Styles */
/* Services */

/**
 * Renders TeacherShare component
 * @param {Object} SectionContent
 * @returns {ReactElement} Teacher Share Experience component
 */
const TeacherShareExper = ({ sectionContent }) => {
  const bgOverlay = {
    green: `${config.imgPath}/d/46272/1701152835-group-1171276895.png`,
    brown: `${config.imgPath}/d/46272/1701152840-group-1171277600.png`,
    orange: `${config.imgPath}/d/46272/1699600114-group-1171277325.png`,
  };
  // console.log(sectionContent, "teacher share experience", bgOverlay[render(sectionContent.testimonials?.[0]?.additionalDetails).replace("<p>", "").replace("</p>", "")])
  return (
    <Box
      px={{ base: '30px', md: '0' }}
      mt={{ base: '15px', md: '25px' }}
      maxW={760}
      mx="auto"
      w="100%"
    >
      {sectionContent.testimonials.map(item => {
        const bgColor = render(item?.additionalDetails)
          .replace('<p>', '')
          .replace('</p>', '');
        return (
          <>
            <Box
              position="relative"
              key={nanoid(10)}
              pb={{ base: '30px', md: '50px' }}
            >
              <Box
                w="full"
                display="flex"
                flexDir={{ base: 'column', md: 'row' }}
                mb={{ base: '0px', md: '60px' }}
              >
                <Box
                  pos="relative"
                  mx="auto"
                  w={{ base: '100%', md: '300px' }}
                  h="300px"
                >
                  <Image
                    src={item?.photo?.url}
                    alt=""
                    objectFit="cover"
                    borderRadius="8px"
                    w="100%"
                    h="100%"
                  />
                  <Box
                    pos="relative"
                    minH="89px"
                    h="auto"
                    mx="auto"
                    bottom="50px"
                    px="5%"
                    py="10px"
                    width="90%"
                    bgImage={bgOverlay[bgColor]}
                    bgRepeat="no-repeat"
                    bgPos="center"
                    display="flex"
                    bgSize="contain"
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text
                      color="#fff"
                      fontWeight="500"
                      fontSize="22px"
                      maxW={{ base: '240px', md: 'auto' }}
                      textAlign="center"
                    >
                      {' '}
                      {item?.userName}
                    </Text>

                    <StructuredTextParser
                      str={render(item?.userDescription?.value)}
                      color="#fff"
                      mt="0px"
                      fontWeight="400"
                      maxW={{ base: '240px', md: '183px' }}
                      textAlign="center"
                      fontFamily="'Zilla slab', serif"
                    />
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column" flexGrow="1">
                  <Box
                    fontStyle="italic"
                    mt={{ base: '70px', md: '0px' }}
                    display={{ base: 'inline-block', md: 'block' }}
                  >
                    <StructuredTextParser
                      str={render(item?.testimonialTitle.value)
                        .replace('<p>', '<span>')
                        .replace('</p>', '</span>')}
                      fontSize="26px"
                      color="#9E7652"
                      mt="24px"
                      lineHeight="32px"
                      fontWeight="500"
                      pl={{ base: '10px', md: '49px' }}
                      display={{ base: 'inline', md: 'block' }}
                    />
                  </Box>
                  <Box display={{ base: 'inline', md: 'block' }}>
                    <StructuredTextParser
                      str={render(item?.testimonialText?.value)
                        .replace('<p>', '<span>')
                        .replace('</p>', '</span>')}
                      fontSize="18px"
                      maxW="409px"
                      mt="13px"
                      lineHeight="30px"
                      mx="0"
                      color="#3B444B"
                      px="0"
                      ml={{ base: '10px', md: '49px' }}
                      display={{ base: 'inline', md: 'block' }}
                    />
                    {/* {!readMoreLink && (
                                                <NextLink href="#" passHref legacyBehavior>
                                                    <Link
                                                        px={{ base: "10px", md: "50px" }}
                                                        fontWeight="600"
                                                        color="#DC6704"
                                                        fontSize="18px"
                                                        textDecor="underline"
                                                        width="140px"
                                                        flexGrow={1}
                                                        _hover={{ color: '#622210' }}
                                                        onClick={e => readMoreHandler(e)}
                                                    >
                                                        Read more


                                                    </Link>
                                                </NextLink>
                                            )} */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        );
      })}
    </Box>
  );
};

export default TeacherShareExper;
