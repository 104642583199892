/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import config from '@config';
import FaqExpandedSection from '@ihs/components/Accordions/FaqExpandedSection';
import FaqSectionV2 from '@ihs/components/Accordions/FaqSectionV2';
import LandingPageTopBanner from '@ihs/components/Banners/LandingPageTopBanner';
import SimpleButton from '@ihs/components/Buttons/SimpleButton';
import CardInfo2 from '@ihs/components/Cards/CardInfo';
import CardZigZagGroup from '@ihs/components/Cards/CardZigZagGroup';
import ContactCard from '@ihs/components/Cards/ContactCard';
import Quote from '@ihs/components/Cards/Quote';
import QuoteImageSignature from '@ihs/components/Cards/QuoteImageSignature';
import SimpleCardGroup from '@ihs/components/Cards/SimpleCardGroup';
// import TopicBasedWisdomContent from '@ihs/components/Cards/TopicBasedWisdomContent';
import GoogleMap from '@ihs/components/Embeds/GoogleMap';
import SectionTitle from '@ihs/components/Headings/SectionTitle';
import TopKolam from '@ihs/components/Headings/TopKolam';
import HillImageImage from '@ihs/components/Img/HillImageImage';
import SingleImage from '@ihs/components/Img/SingleImage';
import MidContainer from '@ihs/components/Layout/MidContainer';
import Gallery from '@ihs/components/Sliders/Gallery';
import TestimonialsGroupV2 from '@ihs/components/Sliders/TestimonialsGroupV2';
import SingleLineText from '@ihs/components/Text/SingleLineText';
import StructuredTextParser from '@ihs/components/Utility/StructuredTextParser';
import TeacherShareExper from '@ihs/sections/TeacherShareExper';
import TopBannerV2 from '@ihs/sections/TopBannerV2';

/* Services */
/* Styles */

/**
 * Renders the CommonComponents Component
 *
 * @param {object} sectionContent - Content from API
 * @param {string} language - Language of page
 * @param {string} region - Region of Page
 * @param {boolean} isLeftMenu - Left Navbar
 * @param {string} pType
 * @param {string} pageName
 * @returns {ReactElement} CommonComponents component
 */

const CommonComponents = ({
  sectionContent,
  region,
  language,
  section,
  isLeftMenu,
  pType,
  pageName,
  csrData,
  pageData,
  wisdomData,
  index,
}) => {
  const router = useRouter();

  //  console.log("common", sectionContent?.fieldId);
  return (
    <Box w="100%" display="flex" flexDirection="column" pb="0">
      {RenderComponents({
        sectionContent,
        region,
        language,
        section,
        isLeftMenu,
        pType,
        pageName,
        csrData,
        wisdomData,
        router,
        pageData,
        index,
      })}
    </Box>
  );
};

const RenderComponents = ({ sectionContent, region, language, wisdomData }) => {
  switch (sectionContent._modelApiKey) {
    case 'landing_page_top_banner':
      return (
        <LandingPageTopBanner
          sectionContent={sectionContent}
          region={region}
          language={language}
        />
      );
    case 'top_kolam':
      return <TopKolam sectionContent={sectionContent} />;
    case 'testimonials_group_v2':
      return (
        <Box
          display="flex"
          border="2px"
          borderColor="transparent"
          placeContent="center"
        >
          <TeacherShareExper sectionContent={sectionContent} />
        </Box>
      );
    case 'testimonials_group':
      return <TestimonialsGroupV2 sectionContent={sectionContent} />;
    case 'top_banner_v2':
      return <TopBannerV2 sectionContent={sectionContent} />;
    case 'structured_text':
      return (
        <MidContainer w={{ base: '100%', md: '645px', lg: '670px' }}>
          <Box className="allTextFormat">
            <StructuredTextParser
              str={render(sectionContent.body)}
              region={region}
              sectionContent={sectionContent}
              lang={language}
              textAlign={sectionContent.style}
              maxW="none"
              lineHeight="30px"
            />
          </Box>
        </MidContainer>
      );
    case 'single_line_text':
      return (
        <Box>
          <SingleLineText sectionContent={sectionContent} />
        </Box>
      );
    case 'single_image':
      if (sectionContent.fieldId === 'hill-image') {
        return <HillImageImage sectionContent={sectionContent} />;
      }
      return (
        <SingleImage
          sectionContent={sectionContent}
          region={region}
          lang={language}
          maxWidth="670px"
        />
      );
    case 'ihs_contact_card_group':
      return <ContactCard sectionContent={sectionContent} />;
    case 'section_title':
      return (
        <SectionTitle
          titleObj={sectionContent}
          // maxW={{ base: '280px', sm: '350px', md: '663px' }}
          mx={{ base: '0', md: 'auto' }}
        />
      );
    case 'simple_button':
      return (
        <SimpleButton
          style={sectionContent}
          region={region}
          lang={language}
          mx="auto"
        />
      );
    case 'google_map':
      return (
        <Box maxW="670px" mx="auto" w="100%" px={{ base: '20px', md: '0' }}>
          <GoogleMap sectionContent={sectionContent} maxW={670} />
        </Box>
      );
    case 'faq_expanded_section':
      return (
        <FaqExpandedSection
          faqSection={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'faq_section_v2':
      return <FaqSectionV2 faqSection={sectionContent} />;
    case 'gallery':
      return (
        <Box>
          <Gallery sectionContent={sectionContent} />
        </Box>
      );
    case 'quote':
      if (sectionContent.decoration === 'quote-symbol-image-signature') {
        return <QuoteImageSignature sectionContent={sectionContent} />;
      }
      return <Quote sectionContent={sectionContent} />;
    case 'newsletter_subscription_form':
      const SubscribeContent = dynamic(() =>
        import('@ihs/components/UI/Subscribe/SubscribeContent')
      );
      return (
        <Box
          py="50px"
          // bgColor={pType === 'center' ? '' : '#f5f0e5'}
          bgImage={{
            base: `${config.imgPath}/d/46272/1699264941-vector-22-1.png`,
            md: `${config.imgPath}/d/46272/1699264935-vector-22.png`,
          }}
          bgSize="cover"
          bgPos="top"
          display="flex"
          maxW={1330}
          mx="auto"
          width="100%"
          justifyContent="center"
        >
          <SubscribeContent sectionContent={sectionContent} />
        </Box>
      );
    case 'card_zigzag_group':
      return (
        <Box>
          <CardZigZagGroup sectionContent={sectionContent} />
        </Box>
      );
    case 'simple_card_group':
      return (
        <Box>
          <SimpleCardGroup sectionContent={sectionContent} />
        </Box>
      );
    case 'card_info2_column_group':
      // const CardInfo2 = dynamic(() => import('@components/Card/CardInfo2'));
      return (
        <MidContainer w={'768'}>
          <Box display="flex" marginTop="50px">
            <CardInfo2
              sectionContent={sectionContent}
              region={region}
              lang={language}
            />
          </Box>
        </MidContainer>
      );
    case 'topic_based_wisdom_content':
      const TopicBasedWisdomContent = dynamic(() =>
        import('@ihs/components/Cards/TopicBasedWisdomContent')
      );
      return (
        <TopicBasedWisdomContent
          data={sectionContent}
          titleObj={sectionContent}
          wisdomData={wisdomData}
          isShareEnabled
        />
      );
  }
};

export default CommonComponents;
