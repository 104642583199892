/* Built In Imports */
/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  chakra,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import { BiMinusCircle, BiPlusCircle } from 'react-icons/bi';
import 'react-vertical-timeline-component/style.min.css';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@ihs/components/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the Faq component.
 *
 * @param faqSection
 * @returns {ReactElement} FaqSection component.
 */

const FaqSectionV2 = ({ faqSection }) => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [],
  };

  faqSection?.faqs?.map(faq => {
    schemaData.mainEntity.push({
      '@type': 'Question',
      name: faq?.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: render(faq.answer),
      },
    });
  });

  return (
    <Flex px={{ base: '16px', md: '0' }}>
      <Box
        maxW="772px"
        h="auto"
        ml={{ xl: '3%' }}
        mx={{ base: 'auto' }}
        mt="17px"
        mb="41px"
        width="100%"
      >
        {faqSection?.faqs.map((faq, indx, array) => {
          return (
            <Accordion
              allowToggle
              allowMulitple={false}
              key={indx}
              bg="#f9f9f9"
              borderRadius="8px"
              style={{ marginTop: 20 }}
            >
              <AccordionItem
                key={nanoid(4)}
                borderBottom="none"
                borderTop="none"
                maxW="712px"
                minW="300px"
                w="100%"
                //  className="faqSectionItem"
              >
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      borderBottomRadius="8px"
                      _hover={{ background: 'none' }}
                      _focus={{ shadow: 'transparent' }}
                      //w={{ base: '100%',xl: '100%' }}
                      p="20px 30px 20px 30px"
                    >
                      <chakra.div
                        color="#032B43"
                        fontFamily="Zilla Slab"
                        fontSize={{ base: '18px', xl: '20px' }}
                        fontStyle="normal"
                        fontWeight={isExpanded ? '700' : '500'}
                        lineHeight={{ base: '26px', xl: '30px' }}
                        w="100%"
                        flex="1"
                        textAlign="left"
                        //  key={nanoid(4)}
                      >
                        {faq.question}
                      </chakra.div>
                      <Box
                        ml="20px"
                        color="#989383"
                        _hover={{ color: '#622210' }}
                      >
                        {isExpanded ? (
                          <BiMinusCircle fontSize="24px" />
                        ) : (
                          <BiPlusCircle fontSize="24px" />
                        )}
                      </Box>
                    </AccordionButton>

                    <AccordionPanel
                      maxW="670px"
                      w="93%"
                      pl={{ base: '15px', sm: '15px', md: '30px', xl: '30px' }}
                      mt={{ base: '-30px', xl: '-45px' }}
                      borderBottomRadius="8px"
                    >
                      <StructuredTextParser
                        str={render(faq.answer)}
                        fontFamily="Zilla Slab"
                        //    region={region}
                        //      lang={lang}
                        fontWeight={{ base: '600', md: '400' }}
                        lineHeight={{ base: '26px', md: '30px' }}
                        color="#3B444B"
                        className="faqAnswers"
                      />
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          );
        })}
        <style global jsx>
          {`
            .faqAnswers {
              color: #3b444b;
              font-family: Zilla Slab;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 30px;
              padding-top: -30px !important;
            }
          `}
        </style>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
      </Box>
    </Flex>
  );
};

export default FaqSectionV2;
