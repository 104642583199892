/* Built In Imports */
/* External Imports */
import { Box, Heading } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders the SectionTitle component.
 *
 * @param {object} titleObj component.
 * @param {object} props some more data
 * @returns {ReactElement} SectionTitle component.
 */

const SectionTitle = ({ titleObj, ...props }) => {
  const textAlign = {
    "ihs-title": "left",
    "default": "center"
  };

  // console.log(titleObj, "titleObj", titleObj?.titleDecoration, "textAlign")
  return (
    <>
      {(titleObj?.titleText || titleObj?.title) && (
        <Box
          className={`title-${titleObj?.titleDecoration || 'default'}`}
          maxW={{ base: '100%', md: props.w || '663px' }}
          mx={{ base: 'none', md: 'auto' }}
          fontSize={{
            base: titleObj?.fontSize || 'h6',
            md: titleObj?.fontSize || 'h4',
          }}
          lineHeight={{ base: '28px', md: '48px' }}
          color={titleObj?.color || '#DC6704'}
          textAlign={textAlign[titleObj?.style]}
          mt={{
            base: props.mt || (titleObj?.titleDecoration === 'no-decoration' ? '12px' : '30px'),
            md: props.mt || (titleObj?.titleDecoration === 'no-decoration' ? '20px' : '40px')
          }}
          h="auto"
          fontWeight="600"
          w='100%'
          px={{ base: "17px", md: "0" }}
          style={{ textAlign: textAlign[titleObj?.style] }}
          {...props}
        >
          <Heading
            as="h2"
            fontWeight="600"
            fontSize={{ base: "26px", md: "42px" }}
            textAlign={textAlign[titleObj?.style]}
          >
            {titleObj?.titleText || titleObj?.title}
          </Heading>
        </Box>
      )}
    </>
  );
};

export default SectionTitle;