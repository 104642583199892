/* Built In Imports */
/* External Imports */
import { Box, Heading } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */
/* Styles */

/**
 * Renders the Banner component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} Banner component
 */

const TopBannerV2 = ({ sectionContent }) => {
  // console.log('sectionContent', sectionContent)

  return (
    <Box w="full" bg="#fff">
      <Box
        mx="auto"
        px={{ base: '0', md: '16px', xl: '0' }}
        maxW="1330px"
        w="100%"
        h={{ base: '270px', sm: '360px', md: '400px', xl: '600px' }}
        bgImg={{
          base: sectionContent?.mobileImage?.url,
          md: sectionContent?.desktopImage?.url,
        }}
        bgRepeat="no-repeat"
        bgPos={{ base: 'center bottom', md: 'center bottom' }}
        bgSize={{ base: 'cover', md: 'cover' }}
      >
        <Box
          maxW="1078px"
          mx="auto"
          flexDir="column"
          display="flex"
          justifyContent="flex-end"
          h="full"
          pb={{ base: '60px', md: '100px', lg: '142px' }}
        >
          <Heading
            as="h1"
            fontSize={{ base: '42px', md: '68px' }}
            fontWeight="600"
            color="#fff"
            px={{ base: '15px', lg: '0' }}
            textTransform="uppercase"
          >
            {sectionContent?.title}
          </Heading>
        </Box>
      </Box>
    </Box>
  );
};

export default TopBannerV2;
