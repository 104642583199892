/* Built In Imports */
import dynamic from 'next/dynamic';

/* External Imports */
import { Box, Center, Modal, ModalOverlay, useDisclosure } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const LandingPageTopBanner = ({ sectionContent }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const BannerModal = dynamic(() =>
        import('@ihs/components/Banners/BannerModal')
    );
    const desktopImage = sectionContent?.desktopImage?.url
    return (
        <Box
            bgImage={{
                base:
                    sectionContent?.mobileImage?.url ||
                    desktopImage,
                md: desktopImage,
            }}
            h={{ base: '245px', md: '310px', lg: '444px' }}
            // h={{ base: 'auto', md: '60vh', lg: '60vh' }}
            backgroundSize={{
                base: '150% 100px',
                sm: '100% 100px',
                md: '100% 160px',
                lg: '1210px 252px',
            }}
            backgroundRepeat="no-repeat"
            backgroundPosition="bottom"
            display="flex"
            alignItems="center"
            position="relative"
            maxW={{ base: "100%", lg: 1330 }}
            overflow="hidden"
            w="100%"
            mx="auto"
            px={{ base: '0', md: '20px', lg: '0' }}
            mb={{ base: "10px", md: "20px", lg: "50px" }}
        >
            {(sectionContent?.desktopVideoLink || sectionContent?.mobileVideoLink) && (
                <Center
                    position="absolute"
                    top={{ base: "5px", md: "60px", lg: "80px" }}
                    right={{ base: "-12%", sm: "4%", md: "10%", lg: "18%" }}
                    // transform="translateY(-50%)"
                    transformOrigin='top'
                    w="250px"
                    h={{ base: "160px", lg: "200px" }}
                    p="20px"
                    zIndex={5}
                    padding="25px"
                >
                    <Box
                        w={{ base: "94px", md: "102px", lg: "161px" }}
                        h={{ base: "90px", md: "93px", lg: "147px" }}
                        bgImage={`${config.imgPath}/d/46272/1699597325-group-1171276882.png`}
                        bgRepeat="no-repeat"
                        bgPos="center"
                        backgroundSize="100%"
                        cursor="pointer"
                        _hover={{
                            backgroundImage: `${config.imgPath}/d/46272/1699597332-group-1171277442.png`,
                            w: { base: "119px", md: "127px", lg: "220px" },
                            h: { base: "115px", md: "128px", lg: "212px" },
                            backgroundSize: "100%"
                        }}
                        // transition="height 0.4s ease-in"
                        onClick={onOpen}
                    ></Box>
                    <Modal isOpen={isOpen} onClose={onClose} size={{ base: "full", md: "6xl" }} isCentered h={{ base: "295px", md: "auto" }}>
                        <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
                        <BannerModal sectionContent={sectionContent} onClose={onClose} />
                    </Modal>
                </Center>
            )}
        </Box>
    );
};

export default LandingPageTopBanner;