/* Built In Imports */

/* External Imports */
import { Box, Center, Flex, Link, Text, chakra } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { VscArrowRight } from 'react-icons/vsc';

/* Internal Imports */
/* Components */
import config from '@config';
import { refineUrlDomain } from '@ihs/components/Utility/utils';
/* Services */
/* Styles */

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const ContactCard = ({ sectionContent }) => {
    const cardBg = {
        green: `${config.imgPath}/d/46272/1700647432-group-1171276952.jpg`,
        brown: `${config.imgPath}/d/46272/1700647437-red.jpg`,
        blue: `${config.imgPath}/d/46272/1700647427-blue.jpg`,
    };
    const TextWidth = { green: '168px', brown: '271px' };
    const ArrowRight = chakra(VscArrowRight);
    const icon = {
        arrow: (
            <ArrowRight fontWeight="bold" ml="10px" mt="-2px" display="inline" />
        ),
        '': '',
    };
    return (
        <Box maxW={1330} mx="auto" w="100%">
            <Flex
                flexDir="row"
                maxW={670}
                w="100%"
                mx="auto"
                flexWrap="wrap"
                justifyContent={{ base: 'center', md: 'space-between' }}
            >
                {sectionContent.card.map(cards => {
                    return (
                        <Center
                            backgroundRepeat="no-repeat"
                            w="325px"
                            flexDir="column"
                            height="168px"
                            key={nanoid()}
                            mr="7.21px"
                            bg={`url(${cardBg[cards?.style] ||
                                'https://static.sadhguru.org/d/46272/1699600114-group-1171277325.png'
                                }), no-repeat`}
                            mb="20px"
                            backgroundSize="100% 100% !important"
                            bgPos="center"
                            px="27px"
                        >
                            <Text
                                fontSize="18px"
                                fontStyle="normal"
                                fontWeight="600"
                                lineHeight="18px"
                                color="#FFF"
                                textAlign="center"
                                mx="auto"
                                maxW={TextWidth[cards?.style]}
                            >
                                {cards?.title}
                            </Text>
                            {/* <NextLink href={refineUrlDomain(cards?.link?.[0]?.linkUrl)} legacyBehavior passHref> */}
                            {cards?.link.length > 0 && (
                                <Link
                                    mt="11px"
                                    color="#FFBB10"
                                    fontFamily="Zilla Slab"
                                    fontSize="16px"
                                    fontStyle="normal"
                                    fontWeight="500"
                                    lineHeight="20px"
                                    href={refineUrlDomain(cards?.link?.[0]?.linkUrl)}
                                    borderBottom="1px solid transparent"
                                    textDecor="none"
                                    _hover={{ borderBottom: '1px solid #FFBB10' }}
                                    pb="7px"
                                >
                                    {cards?.link?.[0]?.buttonText}{' '}
                                    <span>{icon[cards?.icon]}</span>
                                    {/* {` ${icon[cards?.icon]}`} */}
                                </Link>
                            )}
                            {/* </NextLink> */}
                        </Center>
                    );
                })}
            </Flex>
        </Box>
    );
};

export default ContactCard;
