/* Built In Imports */
/* External Imports */
import { Box, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from 'config';

/* Styles */
/* Services */

/**
 *
 * @param {object} sectionContent
 * @returns
 */

const SingleLineText = ({ sectionContent, textAlign }) => {
  return (
    <>
      <Box w="100%" bg="#fff">
        <Box
          maxW={1330}
          w="100%"
          mx="auto"
          // pl={{ base: '32px', md: '127px' }}
          pos="relative"
        >
          {/* {sectionContent?.fieldId === 'title' && ( */}
          <Text
            as="h2"
            fontSize={{ base: '28px', md: '42px' }}
            lineHeight={{ base: '30px', md: '45px' }}
            fontFamily="'Belanosima', sans-serif"
            fontWeight="600"
            fontStyle="normal"
            color="#484141"
            textAlign={textAlign || "center"}
            textTransform="uppercase"
          >
            {sectionContent.text}
          </Text>
          {/* )} */}
        </Box>
      </Box>
      <Box w="100%" maxW={1330} mx="auto" h="auto" position="relative" p="0">
        <Box pl={{ base: '32px', md: '127px' }} pos="relative">
          {sectionContent?.fieldId === 'subtext' && (
            <>
              <Box pos="absolute" bottom={{ base: '-25px', md: '-23px' }}>
                <Text
                  fontSize={{ base: '14px', md: '19px' }}
                  // lineHeight={{ base: '18px', md: '24px' }}
                  mt={{ base: '-5px', md: '-7px' }}
                  fontFamily="'Belanosima', system-ui, sans-serif"
                  as="h2"
                  fontWeight="400"
                  fontStyle="normal"
                  color="#8D7452"
                  fontVariant="all-small-caps"
                  style={{ fontVariant: 'all-small-caps' }}
                  pos="relative"
                  letterSpacing="0.37em"
                  zIndex={5}
                  textTransform="uppercase"
                >
                  {sectionContent.text}
                </Text>
                {sectionContent?.fieldId === 'subtext' && (
                  <Box
                    data="data-tooltip"
                    width={{ base: '74px', md: '133px' }}
                    height={{ base: '37px', md: '67px' }}
                    maxW="133px"
                    pos="absolute"
                    // bg="yellow"
                    top={{ base: '-9px', md: '-7px' }}
                    left={{ base: '-37px', md: '-62px' }}
                    maxH="67px"
                    // filter="drop-shadow(-1px 4px 4px rgba(0, 0, 0, 0.09)"
                    zIndex={0}
                  >
                    <LazyLoadImageComponent
                      w="100%"
                      h="100%"
                      objectFit="contain"
                      src={`${config.imgPath}/d/46272/1683176128-image-8.png`}
                    />
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SingleLineText;
