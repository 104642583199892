/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import { Box, Button, Icon, Image, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import { downloadUrl } from '@ihs/components/Utility/utils';

/* Services */
/* Styles */

/**
 * Renders the Card SimpleCardGroup Card component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} SimpleCardGroup Card component
 */

const SimpleCardGroup = ({ sectionContent }) => {
  // console.log("card", sectionContent);

  const download = async (url, title) => {
    downloadUrl(url, `year-book-${title}-${new Date().getMilliseconds()}`);
  };
  const [limit, setLimit] = useState(6);
  const loadMore = () => {
    setLimit(limit => limit + 6);
  };
  const cardsToShow = sectionContent?.simpleCards.slice(0, limit);
  // console.log(cardsToShow, "cardsToshow")
  return (
    <Box maxW="670px" m="30px auto 60px">
      <Box
        display="flex"
        gridGap="20px"
        mb="20px"
        flexWrap="wrap"
        justifyContent={{ base: 'center', md: 'start' }}
      >
        {cardsToShow?.map(card => {
          return (
            <Box
              key={nanoid()}
              display="flex"
              flexDir="column"
              flexWrap="wrap"
              w="308px"
              bgColor="#E5EDEE"
              borderBottomRadius="8px"
            >
              <Image src={card?.rectangularImage?.url} alt="" />
              <Box px="24px" py="25px">
                {/*  <Box pb="14px"><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.21191 5.67969H14.8962V19.3113H3.21191V5.67969Z" stroke="#032B43" stroke-width="0.953488" />
                  <path d="M14.8962 10.5481L20.7383 7.62706V17.3639L14.8962 14.4429V10.5481Z" stroke="#032B43" stroke-width="0.953488" />
                </svg>
                </Box>*/}
                <Box
                  fontSize="20px"
                  color="#032B43"
                  fontFamily="Zilla Slab"
                  fontWeight="700"
                  display="flex"
                  justifyContent="space-between"
                >
                  {' '}
                  {card?.title}
                  {card?.linkUrl && (
                    <Box
                      onClick={() => {
                        download(card?.linkUrl, card?.descriptionText);
                      }}
                      cursor="pointer"
                      color="#DA6503"
                      _hover={{ color: '#812C12' }}
                    >
                      <Icon
                        width="25px"
                        height="27px"
                        viewBox="0 0 25px 27px"
                        _hover={{ fill: '#812C12', color: '#812C12' }}
                        fill="#DA6503"
                      >
                        <g clip-path="url(#clip0_2199_18763)">
                          <path d="M22.6472 20.2448H24.9998C23.2009 22.2216 21.4327 24.1648 19.6501 26.1237C17.8721 24.1704 16.1043 22.2282 14.3105 20.2576H16.6586V20.0255C16.6585 18.0896 16.6584 16.1536 16.6585 14.2177C16.6585 13.8333 16.8811 13.5913 17.2698 13.59C18.8507 13.5846 20.4316 13.5846 22.0125 13.5898C22.4296 13.5912 22.6474 13.8309 22.6473 14.2497C22.6471 16.1745 22.6472 18.0994 22.6471 20.0242C22.6471 20.0904 22.6472 20.1565 22.6472 20.2448Z" />
                          <path d="M22.0383 12.9405C21.5894 12.9405 21.1576 12.9431 20.726 12.9354C20.6942 12.9348 20.6395 12.8618 20.6368 12.8199C20.6258 12.6486 20.6303 12.4762 20.6302 12.3042C20.6301 11.4774 20.6303 10.6506 20.6304 9.82376C20.6304 9.75763 20.6304 9.69154 20.6304 9.59771C20.5558 9.59771 20.4923 9.59775 20.4288 9.59771C18.9972 9.59648 17.5653 9.61153 16.134 9.58989C14.3029 9.56222 12.7125 8.15244 12.4405 6.34368C12.4109 6.14711 12.403 5.94577 12.4025 5.7466C12.3993 4.37045 12.4008 2.9943 12.4008 1.61814C12.4008 1.55315 12.4008 1.48816 12.4008 1.41338C12.3293 1.41005 12.2753 1.40535 12.2213 1.40535C10.0295 1.40499 7.83764 1.40419 5.64576 1.40549C4.85909 1.40595 4.21999 1.86499 4.03201 2.56849C3.99528 2.70589 3.97877 2.85246 3.97757 2.995C3.97201 3.66083 3.97511 4.32673 3.97511 4.99264C3.97511 5.0522 3.97511 5.1118 3.97511 5.18095H2.58474C2.58051 5.13391 2.57258 5.08663 2.57252 5.03938C2.57188 4.3291 2.56736 3.61878 2.57348 2.90856C2.5853 1.53784 3.60197 0.324661 4.95425 0.0712186C5.18152 0.0286342 5.41595 0.00569385 5.64712 0.0053276C8.44391 0.000932647 11.2406 0.00326331 14.0373 3.84753e-07C14.1632 -0.000132796 14.2511 0.0342943 14.34 0.125922C16.8698 2.73526 19.403 5.3413 21.9322 7.95124C21.9907 8.01157 22.0415 8.11036 22.0418 8.19146C22.0483 9.72294 22.0467 11.2544 22.0464 12.786C22.0464 12.8297 22.042 12.8735 22.0383 12.9405ZM13.9256 1.72499C13.9216 1.80736 13.9181 1.84578 13.9181 1.88417C13.9177 3.20412 13.9169 4.52407 13.9186 5.84406C13.9188 5.95999 13.9242 6.07806 13.9462 6.19149C14.1583 7.28693 15.1301 8.08482 16.2739 8.09151C17.4996 8.09867 18.7253 8.09341 19.9511 8.09291C19.9918 8.09291 20.0324 8.08568 20.0991 8.07936C18.0376 5.95746 15.9953 3.85531 13.9256 1.72502V1.72499Z" />
                          <path d="M5.82614 12.9824C4.15639 12.9825 2.48664 12.9763 0.816959 12.9868C0.383723 12.9895 -0.00393201 12.6964 3.01095e-05 12.1693C0.0140141 10.3277 0.0048579 8.48593 0.00532403 6.64425C0.00545721 6.1356 0.30175 5.83664 0.807803 5.83654C4.16948 5.83581 7.53112 5.83574 10.8928 5.83634C11.3734 5.83644 11.6844 6.14545 11.6849 6.6265C11.6868 8.4848 11.6875 10.3431 11.6861 12.2014C11.6857 12.6729 11.3729 12.9817 10.9019 12.982C9.20999 12.9829 7.51807 12.9824 5.82611 12.9824L5.82614 12.9824ZM4.4135 11.6053C4.94808 11.6053 5.47351 11.6233 5.99721 11.601C6.74422 11.5692 7.1706 11.1193 7.38828 10.4437C7.60443 9.77269 7.6085 9.08868 7.42547 8.41322C7.27651 7.86352 6.94759 7.45369 6.36965 7.30003C5.72795 7.12942 5.07321 7.21126 4.41353 7.19142V11.6053H4.4135ZM1.95921 11.6171V10.0079C2.25534 9.99141 2.53705 9.98278 2.81743 9.95861C3.25516 9.92089 3.6015 9.73247 3.79484 9.31921C3.92479 9.0415 3.96128 8.7445 3.93638 8.44305C3.88733 7.8493 3.59564 7.23837 2.73646 7.22042C2.16035 7.2084 1.58404 7.2047 1.00784 7.19815C0.998684 7.19805 0.989428 7.2089 0.976443 7.21699V11.6171L1.95921 11.6171ZM8.20075 7.20241V11.6108H9.17583C9.17583 11.081 9.18096 10.5653 9.1725 10.0498C9.17017 9.90704 9.21795 9.86768 9.35256 9.87487C9.53508 9.88463 9.71851 9.87767 9.90153 9.87777C10.0877 9.87787 10.2739 9.87777 10.4586 9.87777V9.10739H9.18492V7.95874H10.8496V7.20237H8.20072L8.20075 7.20241Z" />
                          <path d="M2.57921 13.6445H3.97527C3.97527 13.718 3.97527 13.7827 3.97527 13.8474C3.97527 16.2165 3.99119 18.5858 3.96745 20.9546C3.95796 21.9015 4.68579 22.6382 5.65847 22.6306C7.23406 22.6183 8.80985 22.6264 10.3855 22.6264C12.0666 22.6264 13.7477 22.628 15.4287 22.6248C15.545 22.6246 15.6217 22.6615 15.6984 22.7476C16.0746 23.1696 16.4576 23.5854 16.8601 24.0273C16.7881 24.0327 16.7431 24.0389 16.6982 24.0389C12.9865 24.0394 9.27472 24.0426 5.56298 24.0377C4.03683 24.0357 2.76792 22.9026 2.5832 21.3947C2.57045 21.2906 2.57258 21.1842 2.57258 21.0789C2.57208 18.6432 2.57225 16.2076 2.57258 13.7719C2.57258 13.7339 2.57641 13.6959 2.57921 13.6445Z" />
                          <path d="M5.41605 7.97034C5.5422 7.97034 5.65464 7.95646 5.76248 7.97281C6.16219 8.03344 6.36346 8.25692 6.45456 8.76903C6.53676 9.2311 6.52814 9.69783 6.42256 10.1556C6.29627 10.703 6.00064 10.8996 5.41602 10.8427V7.97034H5.41605Z" />
                          <path d="M1.95898 9.20262V7.91992C2.24056 7.95279 2.54268 7.82473 2.76273 8.10078C2.92331 8.30222 2.9335 8.76159 2.79276 9.00518C2.68165 9.19752 2.39632 9.26694 1.95898 9.20262Z" />
                        </g>
                        <defs>
                          <clipPath id="clip0_2199_18763">
                            <rect width="25" height="26.1267" fill="white" />
                          </clipPath>
                        </defs>
                      </Icon>
                    </Box>
                  )}
                </Box>
                <Text
                  py="14px"
                  fontWeight="600"
                  color="#3B444B"
                  fontSize="16px"
                  lineHeight="20.02px"
                >
                  {' '}
                  {card?.descriptionText}{' '}
                </Text>
              </Box>
            </Box>
          );
        })}
        {limit < sectionContent?.simpleCards.length ? (
          <Button
            onClick={loadMore}
            variant="outline"
            color="#622210"
            borderColor="#622210"
            _hover={{ boxShadow: 'none' }}
            outline="none"
            mb={{ base: '20px', md: '40px' }}
            _active={{ boxShadow: 'none' }}
            _focus={{ boxShadow: 'none' }}
            fontFamily="Zilla Slab"
          >
            LOAD MORE {limit} / {sectionContent?.simpleCards.length}
          </Button>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};

export default SimpleCardGroup;
