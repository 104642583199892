/* Built In Imports */
/* External Imports */
import { Box, Center, Flex, List, Text, VStack } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5";
import Slider from 'react-slick';
/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import SadhguruImage from '@ihs/components/Icons/SadhguruImage';

/* Services */
/* Styles */

/**
 * Renders the TestimonialsGroupV2 component.
 *
 * @param {object} testimonialsObj component.
 * @param testimonialsObj.sectionContent
 * @param {string} region - Region of page
 * @param {string} lang - Language of page
 * @param testimonialsObj.myRef
 * @param testimonialsObj.region
 * @param testimonialsObj.lang
 * @returns {ReactElement} TestimonialsGroupV2 component.
 */
const TestimonialsGroupV2 = ({ sectionContent }) => {
  // console.log("Image", sectionContent);
  const SliderCard = ({ testimonial }) => {
    return <>
      <Box
        w="100%"
        height={{ base: '714px', md: '583px' }}
        // position="relative"
        zIndex="2"
      >
        <Box>
          <Center>
            <Flex
              position="absolute"
              top="0px"
              flexDir={{ base: "column", md: "column", xl: "column" }} >

              <Box 
                h="auto"
                display="flex"
                flexDir={{ base: "column", md: "row", xl: "row" }} 
                top="0"
              >
                 <Box
                  w={{ base: '71px', sm: '', md: '85.5', xl: '118.5px' }}
                  h={{ base: "50px", md: "68px", xl: "83.4px" }}
                  mx={{ base: "auto" }}
                  pt={{ base: "60px", xl: "132px" }}
                  //  pb={{base:'38px'}}
                  textAlign="center"
                  position="relative"
                  zIndex="2"
                //  paddingLeft="20px"
                //  paddingRight="10px"
                >
                  <LazyLoadImageComponent
                    alt=""
                    title=""
                    src={`${config.imgPath}/d/46272/1699417022-quotefirst.png`}
                  />
                </Box>

                <Text 
                  fontSize={{ base: '22px', md: '25px', xl: '30px' }}
                  maxW={{ base: '230px', md: '393px', xl: '635px' }} 
                  maxH={{ base: '190px', xl: '102px' }}
                  w="100%" height="auto"
                  pt={{ base: "92px", md: "0", xl: "0" }}
                  color="#fff"
                  lineHeight={{ base: '28px', md: "35px", xl: '32px' }}
                  marginBottom="10px"
                  fontStyle="italic"
                  fontWeight="380"
                  textAlign="center"
                  fontFamily="Zilla Slab"
                  display="flex"
                  alignSelf={{ base: "end", md: "center", lg: 'end' }}
                >
                  {testimonial?.testimonialText}
                </Text>

                <Box 
                  justifyContent="flex-end" 
                  w={{ base: '71px', sm: '', md: '85.5', xl: '118.5px' }}
                  h={{ base: "50px", md: "68px", xl: "83.4px" }}
                  ml={{
                    base: 0, xl: "0"
                  }} 
                  pt={{ md: "348px", lg: "369px", xl: "279px" }}
                  display={{ base: "none", md: "block", xl: "block" }}
                  //   textAlign="center"
                  position="relative"
                  zIndex="2"
                >
                  <LazyLoadImageComponent
                    alt=""
                    title=""
                    src={`${config.imgPath}/d/46272/1699417036-quotelast.png`}
                  />
                </Box>
              </Box>

              <Box top="0" display="flex" mx="auto"  >
                <VStack>
                  <Center>
                    <SadhguruImage mx="auto" />
                  </Center>
                </VStack>
              </Box>
            </Flex>
          </Center>
        </Box>
      </Box>
    </>;
  };

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        textAlign='center'
        display="flex"
        justifyContent="center"
        alignItems="center"
        top={{ base: "33%", md: "45%" }}
        zIndex="10"
        cursor="pointer"
        mr={{ base: "6px", md: "48px", lg: "48px", xl: "48px" }}
        border="0"
        w={{ base: "38px", md: "48px", lg: "48px", xl: "48px" }}
        h={{ base: "38px", md: "48px", lg: "48px", xl: "48px" }}
        right={{ xl: '0px', base: '3px' }}
        // bgSize="13px"
        onClick={onClick}
        _hover={{ color: "#fff", bgImage: `url(${config.imgPath}/d/46272/1701325617-left-hover-bg.svg)` }}
        //  background="#FBAD18"
        background={`url(${config.imgPath}/d/46272/1701245566-arrow-left.svg)`}
        bgPos="center"
        bgRepeat="no-repeat"
        placeItems="center"
        bgSize="contain"
        fontSize= "20px"
      >
        <IoChevronForwardSharp />
      </Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        textAlign='center'
        display="flex"
        top={{ base: "33%", md: "45%" }}
        zIndex="10"
        cursor="pointer"
        ml={{ base: "6px", md: "48px", lg: "48px", xl: "48px" }}
        border="0"
        w={{ base: "38px", md: "48px", lg: "48px", xl: "48px" }}
        h={{ base: "38px", md: "48px", lg: "48px", xl: "48px" }}
        left={{ xl: '0px', base: '3px' }}
        // bgSize="13px"
        onClick={onClick}
        _hover={{ color: "#fff", bgImage: `url(${config.imgPath}/d/46272/1701325617-left-hover-bg.svg)` }}
        //  background="#FBAD18"
        background={`url(${config.imgPath}/d/46272/1701245566-arrow-left.svg)`}
        bgPos="center"
        bgRepeat="no-repeat"
        justifyContent="center"
        alignItems="center"
        bgSize="contain"
        fontSize="20px"
      >
        <IoChevronBackSharp />
      </Box>
    );
  };

  const settingsSmall = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    //  centerPadding: '25px',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: dots => (
      <Box
        display={{ base: 'block', md: 'block', xl: 'block' }}
        position='absolute'
        bottom={{ base: '87px', md: '8%', xl: '12%' }}
      >
        <List margin="0px">
          {dots}
        </List>
      </Box>
    ),
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box pt="40px" maxW="1330px" w='100%' mx="auto" position="relative"
      objectFit="cover">
      <Box position="absolute" top={0} left={0} width="100%" height="100%" display={{ base: "block", md: "block", lg: "block", xl: "block" }}>

        { /* <LazyLoadImageComponent
          h={{ base: "714px", md: "629px", xl: "593px", '2xl': '710px' }}
          alt=""
          title=""
          src={`${config.imgPath}/d/46272/1699417030-ihshometestimonial.png`}
        /> */}
        <Box display={{ base: 'none', md: 'none', xl: 'block' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="583" viewBox="0 0 1330 583" fill="none">
            <path d="M339.369 573.46C18.9509 585.724 14.7343 585.501 -16.8854 576.568C-48.505 567.636 -48.505 427.302 -48.505 416.914C-48.505 403.929 -5.39893 322.81 -30.6957 267.982C-55.9926 213.155 -48.505 168.749 -48.505 147.828C-48.505 126.907 -65.3691 45.3881 -48.505 19.4173C-31.6409 -6.55347 -16.8854 10.7604 40.0317 3.54626C96.9487 -3.66779 225.537 3.54626 297.21 3.54626C368.882 3.54626 647.2 7.30859 703.13 7.30859C785.37 7.30859 813.674 12.2032 975.991 3.54626C1138.31 -5.1106 1186.79 4.98914 1264.79 3.54626C1342.79 2.10345 1384.95 12.2032 1384.95 19.4173C1384.95 26.6314 1353.33 28.7957 1384.95 54.045C1416.57 79.2943 1384.95 127.629 1384.95 137.007V258.925C1384.95 276.961 1384.95 339.723 1353.33 377.958C1321.7 416.193 1353.33 484.005 1353.33 502.04C1353.33 520.076 1380.73 543.161 1353.33 557.589C1331.4 569.132 1258.46 572.979 1224.74 573.46C1125.66 568.651 898.373 568.79 827.543 567.636C739.007 566.193 659.788 561.196 339.369 573.46Z" fill="#0D3B65" />
          </svg></Box>
        <Box display={{ base: 'none', md: 'block', xl: 'none' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="629" viewBox="0 0 834 629" fill="none">
            <path d="M191.014 618.707C-27.0024 631.939 -29.8715 631.699 -51.3859 622.061C-72.9002 612.423 -72.9002 461.017 -72.9002 449.81C-72.9002 435.8 -43.5703 348.28 -60.7826 289.127C-77.9949 229.974 -72.9002 182.064 -72.9002 159.492C-72.9002 136.921 -84.3748 48.9693 -72.9002 20.9494C-61.4257 -7.0705 -51.3858 11.6094 -12.6588 3.82611C26.0684 -3.95721 113.561 3.82611 162.328 3.82611C211.095 3.82611 400.466 7.88525 438.522 7.88525C494.478 7.88525 513.737 13.1661 624.179 3.82611C734.622 -5.51385 767.611 5.38275 820.681 3.82611C873.751 2.26947 902.438 13.1661 902.438 20.9494C902.438 28.7327 880.922 31.0677 902.438 58.3093C923.953 85.5508 902.438 137.699 902.438 147.817V279.355C902.438 298.813 902.438 366.528 880.922 407.78C859.407 449.031 880.922 522.194 880.922 541.653C880.922 561.111 899.568 586.017 880.922 601.584C866.005 614.037 816.378 618.188 793.429 618.707C726.016 613.518 571.367 613.669 523.174 612.423C462.932 610.867 409.031 605.476 191.014 618.707Z" fill="#0D3B65" />
          </svg></Box>
        <Box display={{ base: 'block', md: 'none', xl: 'none' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="715" viewBox="0 0 360 715" fill="none">
            <path d="M-212.703 702.332C-507.141 717.352 -511.016 717.079 -540.072 706.139C-569.128 695.199 -569.128 523.333 -569.128 510.61C-569.128 494.707 -529.517 395.361 -552.763 328.214C-576.008 261.067 -569.128 206.683 -569.128 181.061C-569.128 155.439 -584.625 55.6024 -569.128 23.796C-553.631 -8.01038 -540.072 13.1938 -487.77 4.35876C-435.468 -4.47632 -317.305 4.35876 -251.444 4.35876C-185.583 4.35876 70.1682 8.96649 121.564 8.96649C197.135 8.96649 223.144 14.9609 372.301 4.35876C521.457 -6.24335 566.01 6.12579 637.683 4.35876C709.355 2.59174 748.097 14.9609 748.097 23.796C748.097 32.6311 719.04 35.2817 748.097 66.2045C777.155 97.1274 748.097 156.323 748.097 167.808V317.121C748.097 339.209 748.097 416.075 719.04 462.901C689.983 509.727 719.04 592.777 719.04 614.864C719.04 636.952 744.222 665.225 719.04 682.895C698.894 697.031 631.871 701.743 600.877 702.332C509.834 696.442 300.976 696.613 235.89 695.199C154.531 693.432 81.7356 687.312 -212.703 702.332Z" fill="#0D3B65" />
          </svg>
        </Box>
      </Box>


      <Box
        w={{ base: '100%', md: '100%', lg: '100%', xl: '100%' }}
        height="auto"
        maxW="1330px"
        // position="relative"
        zIndex="1"
      >
        <Slider {...settingsSmall}>
          {sectionContent?.testimonials?.map((testimonial) => {
            return <SliderCard key={nanoid()} testimonial={testimonial} />;
          })}
        </Slider>
      </Box>
      <style global jsx>
        {`
        .slick-dots li {
          width: 10px;
        }
        .slick-dots li.slick-active button:before {
          color: #FBAD18;          
        }
         .slick-dots li button:before {
          width: 8px !important;
          height: 8px !important;
          font-size: 8px!important;
          color: #ABABAB;
          opacity: 0.4;
         }
        
        @media (max-width: 992px) {
          ul.slick-dots {
            display: block !important;
            bottom: 85px !important;
          }
           @media (max-width: 991px) {
          ul.slick-dots {
            display: none !important;
           
          }
          @media (max-width: 767px) {
            ul.slick-dots {
              display: block !important;
             
            }
            ul.slick-dots {
              bottom: 85px !important;
            }

          
              @media (max-width:1330px){
                .TestimonialGroupV2Home .slick-dots {
                padding-bottom: 89px !important;
              }
              
            }
         `}
      </style>


    </Box>
  );
};

export default TestimonialsGroupV2;
