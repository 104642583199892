/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import AdmissionCircularImage from './AdmissionCircularImage';
/* Services */
/* Styles */

/**
 * Renders the Card Zigzag Group 2 Card component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} CardZigzagGroup Card component
 */

const CardZigzagGroup = ({ sectionContent }) => {
  return (
    <>
      {sectionContent.cards.map((card, index) => {
        return (
          <Box
            maxW="1330"
            mt={{ base: '20px' }}
            mx="auto"
            key={nanoid()}
            display="flex"
            justifyContent="center"
            className="allTextFormat"
          >
            <AdmissionCircularImage
              card={card}
              index={index}
              key={nanoid()}
            />
          </Box>
        );
      })}
    </>
  );
};

export default CardZigzagGroup;
